import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Home from './pages/home.js';
import BuyToken from './pages/buy-token';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { bsc,bscTestnet} from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { Provider } from "react-redux";
import store from './store'
import config from "./config/config";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [infuraProvider({
    apiKey: config.projectId,
  }), publicProvider()],
)

const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: config.projectId,
        version:2
      },
    })
  ],
  publicClient,
  webSocketPublicClient,
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "home", element: <Home /> },
    { path: "buy-token", element: <BuyToken /> },
    { path: "terms", element: <Terms /> },
    { path: "privacy", element: <Privacy /> },
  ]);
  return routes;
};

AOS.init();

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router basename="/">
        <ToastContainer />
        <WagmiConfig config={wconfigInitial}>
        <App />
        </WagmiConfig>
      </Router>
    </Provider>
  );
};

export default AppWrapper;