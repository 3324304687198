let NODE_ENV      = 'production';

let Front_URL     = '';
let Server_URL    = "";
let networkVersion= "";
let busdcontract  = "";
let icocontract   = "";
let tokencontract = "";
let blockchainurl = "";
let bonjourcontract = "";
let oldtokencontract = "";
let projectId = "cb89ebb21cdccb2e1b591e189e27706a";

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

if (NODE_ENV === "production") {
    Front_URL          = 'https://presale.webonjour.com/';
    Server_URL         = 'https://api.webonjour.com/';
    busdcontract       = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    icocontract        = "0x3EcA041FA2CAd62B71C851Ba4883f306AC38713b";//"0xC4B03142E5fBa98d263df4442114ecE95B8C61B4";
    tokencontract      = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";
    bonjourcontract    = "0x02AD27A0Cd5fF292ad81007dFEeBA364e896C73C";
    oldtokencontract   = "0x5B2c022b936F594eFb7D1C27570D4c3DD841A2C5";
    blockchainurl      = "https://bscscan.com/tx/";
    networkVersion     = 56;
}
else if (NODE_ENV === "demo") {
    Front_URL          = 'https://demo-presale-page.pages.dev/';
    Server_URL         = 'https://demopresaleapi.webonjour.com/';
    busdcontract       = "0x97477B622de642e28a7F3F1Af87FF51959bD15Bd";
    icocontract        = "0x33f7E5aA19ddd51e2F7a903eE9EEfBb10bce08c0";
    tokencontract      = "0x830BEB23d72D79dB719CfDBDf86731f96F52aB89";
    bonjourcontract    = "0xA699eA0f303Dc01089Eb12065E99e350dEf96dCA";
    oldtokencontract   = "0x5d9E46Cb08a27C27C3d8acC3689570A1F4c286c7"; 
    blockchainurl      = "https://testnet.bscscan.com/tx/";
    networkVersion     = 97;
}
else {
    Front_URL          = 'http://localhost:3000/';
    Server_URL         = 'http://localhost:2053/';
    busdcontract       = "0x97477B622de642e28a7F3F1Af87FF51959bD15Bd";
    icocontract        = "0x33f7E5aA19ddd51e2F7a903eE9EEfBb10bce08c0";
    tokencontract      = "0x830BEB23d72D79dB719CfDBDf86731f96F52aB89";
    bonjourcontract    = "0xc656FD21c07763f83b869ccbD57DB09779eCC017";
    oldtokencontract   = "0x5d9E46Cb08a27C27C3d8acC3689570A1F4c286c7"; 
    blockchainurl      = "https://testnet.bscscan.com/tx/";
    networkVersion     = 97
}

let key = {
    Front_URL           : Front_URL,
    Server_URL          : Server_URL,
    networkVersion      : networkVersion,
    busdcontract        : busdcontract,
    icocontract         : icocontract,
    tokencontract       : tokencontract,
    bonjourcontract     : bonjourcontract,
    oldtokencontract    : oldtokencontract,
    blockchainurl       : blockchainurl,
    projectId           : projectId 
};

export default key;
