import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import config from "../config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert";
import { getCurAddr } from "../action/apicontroller";
import { type WalletClient, useWalletClient,useDisconnect } from 'wagmi'
import { useConnect } from 'wagmi';
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "../reducers/Actions";

export default function Navbar(props) {
  const { connect, connectors, error, isLoading, pendingConnector } =useConnect();
  const { disconnect } = useDisconnect()
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })
  const [selected, setSelected] = useState("GB");
  const [account, setaccount] = useState("");
  const [status, setstatus] = useState(false);
  const [data, setdata] = useState({});
  const timerRef = useRef(null);
  const dispatch = useDispatch()
  const walletConnection = useSelector((state) => state.walletConnection)

  useEffect(() => {
    loadScript();
    checkConnect()
  }, [walletClient]);

  function checkConnect(){
    console.log(walletClient,"====walletClient")
    if(walletClient){
      const { account, chain, transport } = walletClient
      console.log(account, chain, transport,"====account, chain, transport")
        dispatch(setWallet({
          connect : "yes",
          web3 : transport
        }));
        getdetails()
    }
  }

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    $(document).ready(function () {
      $(".main_navbar ul.navbar-nav li a").click(function () {
        $(".navbar-toggler").trigger("click");
      });
    });
  }

  async function getdetails() {
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != "" &&
      localStorage.getItem("account")
    ) {
      setdata(datas);
      setstatus(true);
    }
  }

  async function connectTrustWallet() {
    try {
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56,
      });
      // const provider = new WalletConnectProvider({
      //   rpc: {
      //     97: "https://data-seed-prebsc-1-s3.binance.org:8545/",
      //   },
      //   chainId: 97
      // });

      provider.on("connect", () => {});
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (result[0] != undefined) {
        if (config.networkVersion == network) {
          setaccount(result[0]);
          localStorage.setItem("account", result[0]);
          localStorage.setItem("wallettype", "trust");
          window.location.href = "/buy-token";
        } else {
          await provider.disconnect();
          toastAlert("error", "please select BSC chain network", "network");
        }
      }
    } catch (err) {
      toastAlert("error", "Error Occured Please Try again", "network");
      localStorage.clear();
    }
  }

  async function connectClick() {
    try {
      await window.ethereum.enable()
      const web3 = new Web3(window.ethereum);
      const result = await web3.eth.getAccounts();
      web3.eth.net.getId().then(async (res) => {
        if (result[0] != undefined) {
          if (res === config.networkVersion) {
            toastAlert("success", "Connected Wallet", "excerciseerr");
            setaccount(result[0]);
            localStorage.setItem("account", result[0]);
            localStorage.setItem("wallettype", "metamask");
            window.location.href = "/buy-token";
          } else {
            toastAlert("error", "Connect BSC Network", "excerciseerr");
            setaccount("");
            return false;
          }
        }
      });
    } catch (err) {
      console.log(err,"=====errrr")
    }
  }

  async function logout(){
    localStorage.clear();
    window.location.href="/";
  }

  if(document.readyState == "complete"){
    myinitcode();
  }else{
    window.addEventListener('load', async (event) => {
      event.preventDefault();
      myinitcode();
    })
  }

  async function connectAccount(connector){
    try{
      let rec =   connect(connector);
      window.$('#connect_wallet_modal').modal('hide');
    }catch(err){
      console.log(err,"====+++errrr")
    }
  }


  async function myinitcode(){
    if (localStorage.getItem("wallettype") && localStorage.getItem("wallettype") == "metamask") {
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts()
            var setacc = result[0];
            localStorage.setItem("account", result[0])
            localStorage.setItem('wallettype', "metamask");
              window.location.reload();
          }, 1000);
        })

        window.ethereum.on('chainChanged', async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if (networkId == config.networkversion) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async () => {
              var result = await web3.eth.getAccounts()
              var setacc = result[0];
              localStorage.setItem("account", result[0])
              localStorage.setItem('wallettype', "metamask");
              window.location.reload();
            }, 1000);
          }
          else {
            localStorage.clear()
            window.location.href="/";
          }
        })
      }
    }
  }

  
  return (
    <>
      <nav className="navbar navbar-expand-xl bg-light main_navbar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar_right">
             {window.location.pathname!="/buy-token" && window.location.pathname!="/privacy" && window.location.pathname!="/terms" &&
                <li class="nav-item">
                  <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    className="nav-link"
                  >
                    Home
                  </Link>
                </li>
              }
              {window.location.pathname!="/buy-token"&& window.location.pathname!="/privacy" && window.location.pathname!="/terms" &&
                <li class="nav-item">
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    className="nav-link"
                  >
                    About
                  </Link>
                </li>
              }
              {window.location.pathname!="/buy-token"&& window.location.pathname!="/privacy" && window.location.pathname!="/terms" &&
                <li class="nav-item">
                  <Link
                    to="whychoose"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    className="nav-link"
                  >
                    Why Choose
                  </Link>
                </li>
              }
              {window.location.pathname!="/buy-token" && window.location.pathname!="/privacy" && window.location.pathname!="/terms" &&                
              <li class="nav-item">
                  <Link
                    to="token"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    className="nav-link"
                  >
                    Token
                  </Link>
                </li>
              }
              {window.location.pathname!="/buy-token" && window.location.pathname!="/privacy" && window.location.pathname!="/terms" &&
                <li class="nav-item">
                  <Link
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    className="nav-link"
                  >
                    Roadmap
                  </Link>
                </li>
              }
              {window.location.pathname!="/buy-token" && window.location.pathname!="/privacy" && window.location.pathname!="/terms" &&                
              <li class="nav-item">
                  <Link
                    to="faq"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    className="nav-link"
                  >
                    Faq
                  </Link>
                </li>
              }
              {(window.location.pathname=="/buy-token" || window.location.pathname=="/privacy" || window.location.pathname=="/terms") &&   
                <li class="nav-item">
                  <NavLink
                    to="/home"                                        
                    className="nav-link"
                  >
                    Home
                  </NavLink>
                </li>
              }
              <li>
                {data &&
                localStorage.getItem("account") &&
                data.address &&
                data.address != null &&
                data.address != undefined &&
                data.address != "" ? (
                  // <button
                  //   className="primary_btn"
                  // >

                  // </button>
                  <div className="dropdown me-3">
                    <button
                      className="primary_btn dropdown-toggle address_btn"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {data.address}
                    </button>
                    <ul
                      className="dropdown-menu walletdrop "
                      aria-labelledby="dropdownMenuLink"
                    >
                      {/* <li>
                        <a className="dropdown-item" href="#">
                          <label>BNB Balance:</label>
                          <span className="ms-2">250.08 </span>
                        </a>
                      </li> */}
                      <li>
                        <a className="dropdown-item" href="/buy-token">
                          Buy Token
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={()=>logout()}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    Connect Wallet
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors && connectors.length>0 && connectors.map((connector,index)=>{
                  var logo  = "metamask.png";
                  var name = "MetaMask";
                  if (connector.name === "MetaMask") {
                      logo = "metamask.png";
                      name = "MetaMask";
                  } else if (connector.name === "WalletConnect") {
                      logo = "trustwallet.png";
                      name = "Trust wallet";
                  }
                  return(
                  <a target="_blank" className="mb-3 d-block" onClick={()=>connectAccount({connector})}>
                    <button type="button" className="connect_wallet_button">
                      <img src={require(`../assets/images/${logo}`)} height="28" width="28" className="img-fluid" /> <span>{name}</span>
                    </button>
                  </a>
                  )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
